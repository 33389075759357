export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 15,
        label: 'menuitems.nfe.text',
        icon: 'ri-dashboard-line',
        link: '/financeiro/pedidos'
    },
    {
        id: 16,
        label: 'menuitems.bloco.text',
        icon: 'ri-dashboard-line',
        link: '/financeiro/pedidos/blocoK'
    },
    {
        id: 17,
        label: 'menuitems.saude.text',
        icon: 'ri-dashboard-line',
        link: '/saude'
    },
    {
        id: 3,
        label: 'menuitems.registers.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 41,
                label: 'menuitems.finance.list.bank-branch',
                link: '/financeiro/agenciaBanc'
            },
            {
                id: 42,
                label: 'menuitems.finance.list.bank',
                link: '/financeiro/bancos'
            },

            {
                id: 31,
                label: 'menuitems.registers.list.companies',
                icon: 'ri-building-fill',
                link: '/cadastros/empresas'
            },
            {
                id: 32,
                label: 'menuitems.registers.list.stocklocation',
                icon: 'ri-stack-fill',
                link: '/cadastros/localestoque'
            },
            {
                id: 33,
                label: 'menuitems.registers.list.customers',
                icon: 'ri-team-fill',
                link: '/cadastros/pessoas'
            },
            {
                id: 34,
                label: 'menuitems.registers.list.products',
                icon: 'ri-archive-fill',
                link: '/cadastros/produtos'
            },
            {
                id: 35,
                label: 'menuitems.registers.list.item',
                icon: 'ri-archive-fill',
                link: '/cadastros/item'
            },
            {
                id: 36,
                label: 'menuitems.registers.list.pricetables',
                icon: 'ri-archive-fill',
                link: '/cadastros/tabela'
            },
            {
                id: 37,
                label: 'menuitems.registers.list.plano',
                icon: 'ri-archive-fill',
                link: '/cadastros/plano'
            },
            {
                id: 38,
                label: 'menuitems.registers.list.venda',
                icon: 'ri-archive-fill',
                link: '/cadastro/venda/'
            },


        ]
    },
    {
        id: 4,
        label: 'menuitems.finance.text',
        icon: 'ri-exchange-funds-line',
        subItems: [
            // {
            //     id: 41,
            //     label: 'menuitems.finance.list.bank-branch',
            //     link: '/financeiro/agenciaBanc'
            // },
            // {
            //     id: 42,
            //     label: 'menuitems.finance.list.bank',
            //     link: '/financeiro/bancos'
            // },
            {
                id: 43,
                label: 'menuitems.finance.list.bills-to-receive',
                link: '/financeiro/contasReceber'
            },
            {
                id: 44,
                label: 'menuitems.finance.list.bills-to-pay',
                link: '/financeiro/contasPagar'
            },
            {
                id: 45,
                label: 'menuitems.finance.list.cash-control',
                link: '/financeiro/controle_de_caixa'
            },
            {
                id: 46,
                label: 'menuitems.finance.list.check-control',
                link: '/financeiro/controle_cheque'
            },
            {
                id: 47,
                label: 'menuitems.finance.list.order-of-service',
                link: '/financeiro/ordem_servico'
            },
            {
                id: 48,
                label: 'Pedidos',
                // link: '/financeiro/pedidos',
                icon: 'ri-exchange-funds-line',
                subItems: [
                    {
                        id: 481,
                        label: 'menuitems.finance.list.orders',
                        link: '/financeiro/pedidos'
                    },
                    {
                        id: 482,
                        label: 'menuitems.finance.list.orders-list-pays',
                        link: '/financeiro/pedidos/listar-compras'
                    }
                ]
            },
            {
                id: 49,
                label: 'Registrar',
                // link: '/financeiro/pedidos',
                icon: 'ri-exchange-funds-line',
                subItems: [
                    {
                        id: 483,
                        label: 'menuitems.finance.list.paymentt',
                        link: '/financeiro/pagamentofornecedor'
                    },
                    {
                        id: 486,
                        label: 'menuitems.finance.list.pagCliente',
                        link: '/financeiro/recebimentoCliente'
                    },
                    {
                        id: 484,
                        label: 'menuitems.finance.list.transfer',
                        link: '/financeiro/transferencia_saldo'
                    },
                    {
                        id: 485,
                        label: 'menuitems.finance.list.confirm',
                        link: '/financeiro/confirmapagamentos'
                    },

                ]
            },
        ]
    },
    {
        id: 8,
        label: 'menuitems.trasnport.text',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 81,
                label: 'menuitems.trasnport.list.abastecimento',
                link: '/carga_transporte/abastecimento'
            },
            {
                id: 82,
                label: 'menuitems.trasnport.list.veiculo',
                link: '/carga_transporte/veiculo'
            },
            {
                id: 83,
                label: 'menuitems.trasnport.list.rotas',
                link: '/carga_transporte/rotas'
            },
            {
                id: 83,
                label: 'menuitems.trasnport.list.motorista',
                link: '/carga_transporte/motorista'
            },
            {
                id: 8,
                label: 'Aviso de Embarque',
                icon: 'ri-account-circle-line',
                subItems: [
                    {
                        id: 81,
                        label: 'menuitems.trasnport.list.cadastrar',
                        link: '/carga_transporte/embarque'
                    },
                    // {
                    //     id: 82,
                    //     label: 'menuitems.trasnport.list.listar',
                    //     link: '/carga_transporte/embarque'
                    // },
                ]

            },
            {
                id: 8,
                label: 'Veiculos',
                icon: 'ri-account-circle-line',
                subItems: [
                    {
                        id: 81,
                        label: 'menuitems.trasnport.list.manutencao',
                        link: '/carga_transporte/manutencao'
                    },
                    {
                        id: 82,
                        label: 'menuitems.trasnport.list.servico',
                        link: '/carga_transporte/servicos'
                    },
                ]

            }
        ]
    },
    {
        id: 5,
        label: 'menuitems.parameters.text',
        icon: 'ri-global-line',
        subItems: [

            {
                id: 51,
                label: 'menuitems.parameters.list.costcenter',
                link: '/parametros/centrocusto',
            },
            {
                id: 52,
                label: 'menuitems.parameters.list.resultcenter',
                link: '/parametros/centroresultado',
            },
            {
                id: 53,
                label: 'menuitems.parameters.list.paymenttypes',
                link: '/parametros/tipospagamento',
            },
            {
                id: 54,
                label: 'menuitems.parameters.list.groups',
                link: '/parametros/grupos',
            },
            {
                id: 55,
                label: 'menuitems.parameters.list.historiesdefault',
                link: '/parametros/historicopadrao',
            },
            {
                id: 58,
                label: 'menuitems.parameters.list.serie',
                link: '/parametros/series',
            },
            {
                id: 60,
                label: 'menuitems.parameters.list.situacao',
                link: '/parametros/situacao',
            },
            {
                id: 56,
                label: 'menuitems.parameters.list.typesofmovement',
                link: '/parametros/tiposmovimento',
            },
            {
                id: 57,
                label: 'menuitems.parameters.list.unidademed',
                link: '/parametros/unidademed',
            },
        ]
    },
    {
        id: 7,
        label: 'menuitems.clothing.text',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 81,
                label: 'menuitems.clothing.list.prod_clothing',
                link: '/processos/confeccaoProduto'
            },

        ]
    },

    {
        id: 9,
        label: 'menuitems.Relatorio.text',
        icon: 'ri-account-circle-line',
        subItems: [

            {
                id: 1,
                label: 'Caixa',
                icon: 'ri-account-circle-line',
                subItems: [
                    {
                        id: 66,
                        label: 'menuitems.Relatorio.list.caixa_contas',
                        link: '/relatorio/caixa/contas_pagar'
                    },
                    {
                        id: 67,
                        label: 'menuitems.Relatorio.list.financeiro',
                        link: '/relatorio/caixa/financeiro_custo'
                    },
                    {
                        id: 68,
                        label: 'menuitems.Relatorio.list.financeiro_receita',
                        link: '/relatorio/caixa/financeiro_receita'
                    },
                    {
                        id: 69,
                        label: 'menuitems.Relatorio.list.list_contas',
                        link: '/relatorio/caixa/list_contas_pagar'
                    },
                    {
                        id: 70,
                        label: 'menuitems.Relatorio.list.periodo',
                        link: '/relatorio/caixa/periodo'
                    },
                    {
                        id: 71,
                        label: 'menuitems.Relatorio.list.sangria',
                        link: '/relatorio/caixa/sangrias_periodo'
                    },
                    {
                        id: 72,
                        label: 'menuitems.Relatorio.list.vendas',
                        link: '/relatorio/caixa/vendas_funcionario'
                    },
                    {
                        id: 73,
                        label: 'menuitems.Relatorio.list.list_receber',
                        link: '/relatorio/caixa/list_contas_receber'
                    },
                ],

            },
            {
                id: 2,
                label: 'Cheques',
                icon: 'ri-account-circle-line',
                subItems: [
                    {
                        id: 74,
                        label: 'menuitems.Relatorio.list.cheques',
                        link: '/relatorio/cheque/cheque_emitido'
                    },
                    {
                        id: 75,
                        label: 'menuitems.Relatorio.list.cheques_receb',
                        link: '/relatorio/cheque/cheque_recebido'
                    },

                ],

            },
            {
                id: 3,
                label: 'Controle de Estoque',
                icon: 'ri-account-circle-line',
                subItems: [
                    {
                        id: 77,
                        label: 'menuitems.Relatorio.list.list_produtos',
                        link: '/relatorio/estoque/list_produtos'
                    },
                    {
                        id: 78,
                        label: 'menuitems.Relatorio.list.estoque_produto',
                        link: '/relatorio/estoque/produto_estoque'
                    },
                    {
                        id: 79,
                        label: 'menuitems.Relatorio.list.tipo_produtos_vendas',
                        link: '/relatorio/estoque/vendas_tipo_produto'
                    },
                    {
                        id: 80,
                        label: 'menuitems.Relatorio.list.vendas_quantidades',
                        link: '/relatorio/estoque/vendas_quantidade_vendas'
                    },
                ],

            },
            {
                id: 81,
                label: 'menuitems.Relatorio.list.list_cliente',
                link: '/relatorio/estoque/list_cliente'
            },
            {
                id: 82,
                label: 'menuitems.Relatorio.list.list_fornecedores',
                link: '/relatorio/estoque/list_fornecedores'
            },
            {
                id: 83,
                label: 'menuitems.Relatorio.list.nfe',
                link: '/relatorio/estoque/relatorio_nfe'
            },
        ],
    },
    {
        id: 6,
        label: 'menuitems.users.text',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 61,
                label: 'menuitems.users.list.insert',
                link: '/register'
            },
            {
                id: 62,
                label: 'menuitems.users.list.changepassword',
                link: '/forgot-password'
            },
            {
                id: 63,
                label: 'menuitems.users.list.list',
                link: '/userGetAll'
            }
        ]
    },
]